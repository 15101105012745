<template>
    <div>
        <validation-observer ref="addClinicianTrackingDetailsRules">
            <b-form
                class="px-2"
            >
                <b-row cols="12">
                    <b-col md="8">
                        <!-- <b-row cols="12"> -->
                        <!-- Tracking Details -->
                        <b-badge variant="light-success" class="float-right p-50" style="font-size: 14px;" v-if="!accounActivetNotActive && !accountNotActive">
                        Active
                        </b-badge>
                        <b-badge variant="light-danger" class="float-right p-50" style="font-size: 14px;" v-else>
                        Not Active
                        </b-badge>
                        <h4>Tracking Details</h4>
                        <hr>
                        <b-col md="6" class="px-0">
                            <!-- Regional CFE Assigned -->
                            <b-form-group
                                label="Regional PGRC Assigned"
                                label-for="Regional PGRC Assigned"
                            >
                                <v-select
                                    v-model="regionalCFE"
                                    placeholder="Regional PGRC Assigned"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="regionalCFEAssigned"
                                    :disabled="dataSpecialist ? true : false"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- </b-row> -->

                        <!-- <b-row> -->
                        <b-col md="6" v-for="(itemDate, index) in datePickers" :key="index" class="px-0">
                            <b-form-group
                                :label="itemDate.text"
                                :label-for="itemDate.text"
                            >
                                <flat-pickr
                                    v-model="twoDaysTrainingCompletedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'twoDaysTrainingCompletedDate'"
                                />
                                <flat-pickr
                                    v-model="webinarCBTCompletedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'webinarCBTCompletedDate'"
                                />
                                <flat-pickr
                                    v-model="webinarMICompletedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'webinarMICompletedDate'"
                                />
                                <flat-pickr
                                    v-model="collaborativeInterviewCompletedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'collaborativeInterviewCompletedDate'"
                                />
                                <flat-pickr
                                    v-model="MOUSignedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'MOUSignedDate'"
                                />
                                <flat-pickr
                                    v-model="malpracticeInsuranceExpirationDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'malpracticeInsuranceExpirationDate'"
                                />
                                <flat-pickr
                                    v-model="sixtyHourCourse"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'sixtyHourCourse'"
                                />
                                <flat-pickr
                                    v-model="licenseExpirationDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'licenseExpirationDate'"
                                />
                                <flat-pickr
                                    v-model="activeDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'activeDate'"
                                />
                                <flat-pickr
                                    v-model="expirationDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'expirationDate'"
                                />
                                <flat-pickr
                                    v-model="advancedTrainingCompletedDate"
                                    :class="dataSpecialist ? 'form-control flat-disabled' : 'form-control'"
                                    placeholder="Select a Date"
                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                    :disabled="dataSpecialist ? true : false"
                                    :style="dataSpecialist ? 'background: #f8f8f8 !important; background-color: #f8f8f8 !important;' : ''"
                                    v-if="itemDate.name === 'advancedTrainingCompletedDate'"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- </b-row> -->
                    </b-col>
                    <b-col md="4">
                        <!-- Notes & Attributes -->
                        <h4>Notes & Attributes</h4>
                        <hr>
                        <!-- Notes -->
                        <b-form-group
                            label="Notes"
                            label-for="notes"
                        >
                            <b-form-textarea
                                placeholder="Notes"
                                rows="3"
                                v-model="notes"
                                :disabled="dataSpecialist ? true : false"
                            />
                        </b-form-group>

                        <b-col md="12">
                            <b-form-checkbox
                                v-model="referenceOneReceived"
                                name="some-radio9"
                                :value="true"
                                class="custom-control-success"
                                :disabled="dataSpecialist ? true : false"
                            >
                            Reference 1 Received
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12">
                            <b-form-checkbox
                                v-model="referenceTwoReceived"
                                name="some-radio9"
                                :value="true"
                                class="custom-control-success"
                                :disabled="dataSpecialist ? true : false"
                            >
                            Reference 2 Received
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12">
                            <b-form-checkbox
                                v-model="referenceThreeReceived"
                                name="some-radio9"
                                :value="true"
                                class="custom-control-success"
                                :disabled="dataSpecialist ? true : false"
                            >
                            Reference 3 Received
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12">
                            <b-form-checkbox
                                v-model="wNine"
                                name="some-radio9"
                                :value="true"
                                class="custom-control-success"
                                :disabled="dataSpecialist ? true : false"
                            >
                            W-9
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12">
                            <b-form-checkbox
                                v-model="directDeposit"
                                name="some-radio9"
                                :value="true"
                                class="custom-control-success"
                                :disabled="dataSpecialist ? true : false"
                            >
                            Direct Deposit
                            </b-form-checkbox>
                        </b-col>
                    </b-col>
                </b-row>

                <!-- Form Actions -->
                <div class="d-flex mt-2 float-right">
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        @click="getBack()"
                    >
                    Back to list
                    </b-button>

                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                        :disabled="dataSpecialist ? true : false"
                        @click.prevent="validationForm"
                        v-if="!dataSpecialist"
                    >
                    Save
                    </b-button>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea, BFormRadio, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import moment from 'moment'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            countries,
            accountNotActive: true,
            accounActivetNotActive: true,
            usuarioDataExpiredDate: '',
            idClinicianDependency: '',

            // settings taxonomies options
            optionsRegion: [],
            optionsProgram: [],
            optionsLicenseType: [],
            optionsInsuranceTypesAccepted: [],
            optionsSpecialities: [],
            optionsLanguages: [],
            regionalCFEAssigned: [],
            optionsAttributes: [],

            // fields form
            regionalCFE: '',
            
            twoDaysTrainingCompletedDate: null,
            webinarCBTCompletedDate: null,
            webinarMICompletedDate: null,
            collaborativeInterviewCompletedDate: null,
            MOUSignedDate: null,
            malpracticeInsuranceExpirationDate: null,
            sixtyHourCourse: null,
            licenseExpirationDate: null,
            activeDate: null,
            expirationDate: null,
            advancedTrainingCompletedDate: null,
            datePickers: [
                {text: 'Application of Treatment Essentials Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                {text: 'CBT Primer Completed Date', name: 'webinarCBTCompletedDate', value: null},
                {text: 'MI Primer Completed Date', name: 'webinarMICompletedDate', value: null},
                {text: 'PG Treatment Specialist Completed Date', name: 'sixtyHourCourse', value: null},
                {text: 'Site Visit Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                {text: 'Clinician Agreement Signed Date', name: 'MOUSignedDate', value: null},
                {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                {text: 'Active Date', name: 'activeDate', value: null},
                {text: 'Expiration Date', name: 'expirationDate', value: null},
                {text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: null}
            ],
            
            // notes
            notes: '',
            referenceOneReceived: false,
            referenceTwoReceived: false,
            referenceThreeReceived: false,
            wNine: false,
            directDeposit: false,
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },
            activeDateDB: null,
            dataSpecialist: false
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BFormRadio,
        BFormCheckbox,
        BBadge,
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: ['editStaffTracking','idClinicianStaff'],
    watch: {
        activeDate(val) {
            // console.log(val, this.activeDateDB)
            if(!this.expirationDate) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(12, 'months')
                var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                this.expirationDate = dateUpdateFormat
                // console.log(this.expirationDate)
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')


                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }

            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')

                if(this.activeDateDB !== val) {
                    var expiredDateReal = moment(formatDate).add(12, 'months')
                    var dateUpdateFormat = moment(expiredDateReal,'MM/DD/YYYY').format('MM-DD-YYYY')
                    this.expirationDate = dateUpdateFormat
                }
                // console.log(this.expirationDate)
                // var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
                
                // var dateUpdateFormat = moment(expiredDate).format('LLLL')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')
                // console.log(this.usuarioDataExpiredDate)
                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }

            // mark as not active
            if(val) {
                // console.log(val)
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var activeDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(activeDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                // console.log(days)
                if(days > 0) {
                    this.accounActivetNotActive = true
                } else {
                    this.accounActivetNotActive = false
                }
            }
        },
        expirationDate(val) {
            if(!val) {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10);
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            } else {
                var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
                var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
                var expiredDate = moment(formatDate).add(0, 's')
                this.usuarioDataExpiredDate = moment(expiredDate).format('x')

                var dateExpired = parseInt(this.usuarioDataExpiredDate, 10)
                var day1 = new Date(); 
                var day2 = new Date(dateExpired);
                var difference= day2-day1;
                var days = difference/(1000 * 3600 * 24);
                if(Math.trunc(days) < 0) {
                    this.accountNotActive = true
                } else {
                    this.accountNotActive = false
                }
            }
        },
        idClinicianStaff(val) {
            if(val) {
                db.collection('usuarios').doc(val).get()
                .then(userDoc => {
                    // depends type of clinician role
                    if(userDoc.data().role === 'clinician-staff') {
                        this.idClinicianDependency = val
                        db.collection('usuarios').doc(val).collection('details').doc('trackingDetail')
                        .onSnapshot(doc => {
                            if(doc.data()) {
                                this.datePickers = []
                                this.regionalCFE = doc.data().regionalCFE ? doc.data().regionalCFE : ''
                                var datePickersAtomic = doc.data().datePickers ? doc.data().datePickers : [
                                    {text: 'Application of Treatment Essentials Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                                    {text: 'CBT Primer Completed Date', name: 'webinarCBTCompletedDate', value: null},
                                    {text: 'MI Primer Completed Date', name: 'webinarMICompletedDate', value: null},
                                    {text: 'PG Treatment Specialist Completed Date', name: 'sixtyHourCourse', value: null},
                                    {text: 'Site Visit Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                                    {text: 'Clinician Agreement Signed Date', name: 'MOUSignedDate', value: null},
                                    {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                                    {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                                    {text: 'Active Date', name: 'activeDate', value: null},
                                    {text: 'Expiration Date', name: 'expirationDate', value: null},
                                ]

                                var picker1 = datePickersAtomic.filter(ele => ele.name === 'twoDaysTrainingCompletedDate')
                                var picker2 = datePickersAtomic.filter(ele => ele.name === 'webinarCBTCompletedDate')
                                var picker3 = datePickersAtomic.filter(ele => ele.name === 'sixtyHourCourse')
                                var picker4 = datePickersAtomic.filter(ele => ele.name === 'webinarMICompletedDate')
                                var picker5 = datePickersAtomic.filter(ele => ele.name === 'collaborativeInterviewCompletedDate')
                                var picker6 = datePickersAtomic.filter(ele => ele.name === 'MOUSignedDate')
                                var picker7 = datePickersAtomic.filter(ele => ele.name === 'malpracticeInsuranceExpirationDate')
                                var picker8 = datePickersAtomic.filter(ele => ele.name === 'licenseExpirationDate')
                                var picker9 = datePickersAtomic.filter(ele => ele.name === 'activeDate')
                                var picker10 = datePickersAtomic.filter(ele => ele.name === 'expirationDate')
                                var picker11v2 = datePickersAtomic.filter(ele => ele.name === 'advancedTrainingCompletedDate')

                                this.datePickers.push({text: 'Application of Treatment Essentials Completed Date', name: picker1[0].name, value: picker1[0].value})
                                this.datePickers.push({text: 'CBT Primer Completed Date', name: picker2[0].name, value: picker2[0].value})
                                this.datePickers.push({text: 'MI Primer Completed Date', name: picker4[0].name, value: picker4[0].value})
                                this.datePickers.push({text: 'PG Treatment Specialist Completed Date', name: picker3[0].name, value: picker3[0].value})
                                this.datePickers.push({text: 'Site Visit Completed Date', name: picker5[0].name, value: picker5[0].value})
                                this.datePickers.push({text: 'Clinician Agreement Signed Date', name: picker6[0].name, value: picker6[0].value})
                                this.datePickers.push({text: 'Malpractice Insurance Expiration Date', name: picker7[0].name, value: picker7[0].value})
                                this.datePickers.push({text: 'License Expiration Date', name: picker8[0].name, value: picker8[0].value})
                                this.datePickers.push({text: 'Active Date', name: picker9[0].name, value: picker9[0].value})
                                this.datePickers.push({text: 'Expiration Date', name: picker10[0].name, value: picker10[0].value})
                                if(this.datePickers.filter(ele => ele.name === 'advancedTrainingCompletedDate').length === 0) {
                                    this.datePickers.push({text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: picker11v2.length ? picker11v2[0].value : null})
                                }
                                var picker11 = this.datePickers.filter(ele => ele.name === 'advancedTrainingCompletedDate')

                                if(doc.data().datePickers) {
                                    this.twoDaysTrainingCompletedDate = picker1[0].value ? picker1[0].value : null
                                    this.webinarCBTCompletedDate = picker2[0].value ? picker2[0].value : null
                                    this.sixtyHourCourse = picker3[0].value ? picker3[0].value : null
                                    this.webinarMICompletedDate = picker4[0].value ? picker4[0].value : null
                                    this.collaborativeInterviewCompletedDate = picker5[0].value ? picker5[0].value : null
                                    this.MOUSignedDate = picker6[0].value ? picker6[0].value : null
                                    this.malpracticeInsuranceExpirationDate = picker7[0].value ? picker7[0].value : null
                                    this.licenseExpirationDate = picker8[0].value ? picker8[0].value : null
                                    this.activeDate = picker9[0].value ? picker9[0].value : null
                                    this.expirationDate = picker10[0].value ? picker10[0].value : null
                                    this.advancedTrainingCompletedDate = picker11[0].value ? picker11[0].value : null

                                    this.activeDateDB = picker9[0].value ? picker9[0].value : null
                                }

                                if(!this.activeDate && !this.expirationDate) {
                                    this.accountNotActive = true
                                }
                                
                                // notes
                                this.notes = doc.data().notes ? doc.data().notes : ''
                                this.referenceOneReceived = doc.data().referenceOneReceived ? doc.data().referenceOneReceived : ''
                                this.referenceTwoReceived = doc.data().referenceTwoReceived ? doc.data().referenceTwoReceived : ''
                                this.referenceThreeReceived = doc.data().referenceThreeReceived ? doc.data().referenceThreeReceived : ''
                                this.wNine = doc.data().wNine ? doc.data().wNine : ''
                                this.directDeposit = doc.data().directDeposit ? doc.data().directDeposit : ''
                            }
                        })
                    }
                })
            }
        }
    },
    methods: {
        ...mapActions(['updateClinicianTrackingDetails']),
        getBack() {
            this.$emit('update:editStaffTracking', false)
        },
        validationForm() {
            this.$refs.addClinicianTrackingDetailsRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.editClinicianTracking()
                }
            })
        },
        editClinicianTracking() {
            this.datePickers.forEach(element => {
                if(element.name === 'twoDaysTrainingCompletedDate') element.value = this.twoDaysTrainingCompletedDate
                if(element.name === 'webinarCBTCompletedDate') element.value = this.webinarCBTCompletedDate
                if(element.name === 'webinarMICompletedDate') element.value = this.webinarMICompletedDate
                if(element.name === 'collaborativeInterviewCompletedDate') element.value = this.collaborativeInterviewCompletedDate
                if(element.name === 'MOUSignedDate') element.value = this.MOUSignedDate
                if(element.name === 'malpracticeInsuranceExpirationDate') element.value = this.malpracticeInsuranceExpirationDate
                if(element.name === 'sixtyHourCourse') element.value = this.sixtyHourCourse
                if(element.name === 'licenseExpirationDate') element.value = this.licenseExpirationDate
                if(element.name === 'activeDate') element.value = this.activeDate
                if(element.name === 'expirationDate') element.value = this.expirationDate
                if(element.name === 'advancedTrainingCompletedDate') element.value = this.advancedTrainingCompletedDate
            })
            // console.log(this.datePickers);

            if(this.accountNotActive || this.accounActivetNotActive) {
                // console.log(this.usuarioDataExpiredDate)
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    expiredDate: this.usuarioDataExpiredDate
                })
            }

            if(!this.accounActivetNotActive && !this.accountNotActive) {
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    statusReferral: 'active'
                })
            } else {
                db.collection('usuarios').doc(this.idClinicianDependency).update({
                    statusReferral: 'inactive'
                })
            }

            let objectTracking = {
                regionalCFE: this.regionalCFE,
                datePickers: this.datePickers,
                
                // notes
                notes: this.notes,
                referenceOneReceived: this.referenceOneReceived,
                referenceTwoReceived: this.referenceTwoReceived,
                referenceThreeReceived: this.referenceThreeReceived,
                wNine: this.wNine,
                directDeposit: this.directDeposit,
            }
            this.updateClinicianTrackingDetails({id: this.idClinicianDependency, data: objectTracking})
        }
    },
    created() {
        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region
            this.optionsProgram = docTaxonomia.data().program
            this.optionsAttributes = docTaxonomia.data().clinicianAttributes

            // clinician details fields
            this.optionsLicenseType = docTaxonomia.data().licenseType
            this.optionsInsuranceTypesAccepted = docTaxonomia.data().insuranceTypesAcepted
            this.optionsSpecialities = docTaxonomia.data().specialities
            this.optionsLanguages = docTaxonomia.data().languages

            // tracking details fields
            this.regionalCFEAssigned = docTaxonomia.data().regionalCFEAssigned
        })

        if(this.idClinicianStaff) {
            db.collection('usuarios').doc(this.idClinicianStaff).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    // this.adminOrManageUsers = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        // this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        // this.clinicianOrClinicianViewer = true
                    } else {
                        // this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    // this.clinicianUsers = true
                }

                // depends type of clinician role
                if(userDoc.data().role === 'clinician-staff') {
                    this.idClinicianDependency = this.idClinicianStaff
                    db.collection('usuarios').doc(this.idClinicianStaff).collection('details').doc('trackingDetail')
                    .onSnapshot(doc => {
                        if(doc.data()) {
                            this.datePickers = []
                            this.regionalCFE = doc.data().regionalCFE ? doc.data().regionalCFE : ''
                            var datePickersAtomic = doc.data().datePickers ? doc.data().datePickers : [
                                {text: 'Application of Treatment Essentials Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                                {text: 'CBT Primer Completed Date', name: 'webinarCBTCompletedDate', value: null},
                                {text: 'MI Primer Completed Date', name: 'webinarMICompletedDate', value: null},
                                {text: 'PG Treatment Specialist Completed Date', name: 'sixtyHourCourse', value: null},
                                {text: 'Site Visit Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                                {text: 'Clinician Agreement Signed Date', name: 'MOUSignedDate', value: null},
                                {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                                {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                                {text: 'Active Date', name: 'activeDate', value: null},
                                {text: 'Expiration Date', name: 'expirationDate', value: null},
                            ]

                            var picker1 = datePickersAtomic.filter(ele => ele.name === 'twoDaysTrainingCompletedDate')
                            var picker2 = datePickersAtomic.filter(ele => ele.name === 'webinarCBTCompletedDate')
                            var picker3 = datePickersAtomic.filter(ele => ele.name === 'sixtyHourCourse')
                            var picker4 = datePickersAtomic.filter(ele => ele.name === 'webinarMICompletedDate')
                            var picker5 = datePickersAtomic.filter(ele => ele.name === 'collaborativeInterviewCompletedDate')
                            var picker6 = datePickersAtomic.filter(ele => ele.name === 'MOUSignedDate')
                            var picker7 = datePickersAtomic.filter(ele => ele.name === 'malpracticeInsuranceExpirationDate')
                            var picker8 = datePickersAtomic.filter(ele => ele.name === 'licenseExpirationDate')
                            var picker9 = datePickersAtomic.filter(ele => ele.name === 'activeDate')
                            var picker10 = datePickersAtomic.filter(ele => ele.name === 'expirationDate')
                            var picker11v2 = datePickersAtomic.filter(ele => ele.name === 'advancedTrainingCompletedDate')

                            this.datePickers.push({text: 'Application of Treatment Essentials Completed Date', name: picker1[0].name, value: picker1[0].value})
                            this.datePickers.push({text: 'CBT Primer Completed Date', name: picker2[0].name, value: picker2[0].value})
                            this.datePickers.push({text: 'MI Primer Completed Date', name: picker4[0].name, value: picker4[0].value})
                            this.datePickers.push({text: 'PG Treatment Specialist Completed Date', name: picker3[0].name, value: picker3[0].value})
                            this.datePickers.push({text: 'Site Visit Completed Date', name: picker5[0].name, value: picker5[0].value})
                            this.datePickers.push({text: 'Clinician Agreement Signed Date', name: picker6[0].name, value: picker6[0].value})
                            this.datePickers.push({text: 'Malpractice Insurance Expiration Date', name: picker7[0].name, value: picker7[0].value})
                            this.datePickers.push({text: 'License Expiration Date', name: picker8[0].name, value: picker8[0].value})
                            this.datePickers.push({text: 'Active Date', name: picker9[0].name, value: picker9[0].value})
                            this.datePickers.push({text: 'Expiration Date', name: picker10[0].name, value: picker10[0].value})
                            if(this.datePickers.filter(ele => ele.name === 'advancedTrainingCompletedDate').length === 0) {
                                this.datePickers.push({text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: picker11v2.length ? picker11v2[0].value : null})
                            }
                            var picker11 = this.datePickers.filter(ele => ele.name === 'advancedTrainingCompletedDate')

                            if(doc.data().datePickers) {
                                this.twoDaysTrainingCompletedDate = picker1[0].value ? picker1[0].value : null
                                this.webinarCBTCompletedDate = picker2[0].value ? picker2[0].value : null
                                this.sixtyHourCourse = picker3[0].value ? picker3[0].value : null
                                this.webinarMICompletedDate = picker4[0].value ? picker4[0].value : null
                                this.collaborativeInterviewCompletedDate = picker5[0].value ? picker5[0].value : null
                                this.MOUSignedDate = picker6[0].value ? picker6[0].value : null
                                this.malpracticeInsuranceExpirationDate = picker7[0].value ? picker7[0].value : null
                                this.licenseExpirationDate = picker8[0].value ? picker8[0].value : null
                                this.activeDate = picker9[0].value ? picker9[0].value : null
                                this.expirationDate = picker10[0].value ? picker10[0].value : null
                                this.advancedTrainingCompletedDate = picker11[0].value ? picker11[0].value : null

                                this.activeDateDB = picker9[0].value ? picker9[0].value : null
                            }

                            if(!this.activeDate && !this.expirationDate) {
                                this.accountNotActive = true
                            }
                            
                            // notes
                            this.notes = doc.data().notes ? doc.data().notes : ''
                            this.referenceOneReceived = doc.data().referenceOneReceived ? doc.data().referenceOneReceived : ''
                            this.referenceTwoReceived = doc.data().referenceTwoReceived ? doc.data().referenceTwoReceived : ''
                            this.referenceThreeReceived = doc.data().referenceThreeReceived ? doc.data().referenceThreeReceived : ''
                            this.wNine = doc.data().wNine ? doc.data().wNine : ''
                            this.directDeposit = doc.data().directDeposit ? doc.data().directDeposit : ''
                        }
                    })
                }
            })
        }
    }
}
</script>

<style>
.flat-disabled .flatpickr-input ~ .form-control[readonly] {
    background: #f8f8f8 !important; background-color: #f8f8f8 !important; cursor: no-drop !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>